import React from "react";
import { useEffect, useState } from "react";
import getAllCoinData from "../../api/coinData/getAllCoinData";
import getUserCoinData from "../../api/coinData/userCoinData";
import MintModal from "../../modals/mint";
import TransectionModal from "../../modals/transaction";
import coinMint from "../../api/coinActions/coinMint";
import UserWalletData from "../../api/walletData.js/userWalletData";
import ErrorModal from "../../modals/ErrorModal";
import { toastMessageFailure, toastMessageInfo, toastMessageSuccess } from "../../utils/HelperMethod";
import coinBalance from "../../api/coinData/coinBalance";
import coinTransfer from "../../api/coinActions/coinTransfer";
import { constant } from "../../config/config";
import jwt from "../../utils/jwt";
import successModal from "../../modals/successModal";
import { BarLoader, BeatLoader } from "react-spinners";
import { encrypt } from "../../utils/HelperMethod";
import ErrorAndLogoutModal from "../../modals/ErrorAndLogoutModal";

import { getUserCoin, getUserWalletAndBalance, getCoinBalance, getAllCoin } from "../../api/coin/coin";

import Loader from "../loader";
import { isEmpty } from "lodash";
import { errorMessage } from "../../utils/HelperMethod";
// import { login } from "../../api/auth/auth";

const Main = () => {
  const [activeTab, setActiveTab] = useState("coinList");
  const [allCoinData, setAllCoinData] = useState([]);
  const [userCoinData, setUserCoinData] = useState([]);
  const [userWalletData, setUserWalletData] = useState([]);
  const [userCoinBalance, setUserCoinBalance] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [balances, setBalances] = useState({});

  const [walletAddress, setUserWalletAddress] = useState("");
  const [walletBalance, setWalletBalance] = useState();
  const [myCoinLoader, setMyCoinLoader] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(false);
  const [visible, setVisible] = useState(false);


  useEffect(() => {
    setMyCoinLoader(true)
    getUserWalletAndBalanceInfo()


  }, [])
  const getUserWalletAndBalanceInfo = async (e) => {

    try {
      let { data } = await getUserWalletAndBalance()
      // if user wallet not exist show model and logout the user
      if (isEmpty(data.data.address)) {
        setMyCoinLoader(false)
        setVisible(true)
        return
      }

      setUserWalletAddress(data.data.address)
      setWalletBalance(data.data.balance)


    }
    catch (e) {
      setMyCoinLoader(false)
      let msg = errorMessage(e)
      toastMessageFailure(msg)
    }
  };

  useEffect(() => {
    if (!isEmpty(walletAddress)) {
      getAllCoinDetail()
      getUserCoinDetail()
    }
  }, [walletAddress])


  const getUserCoinDetail = async (e) => {

    try {
      let { data } = await getUserCoin();
      const getBalanceForItem = async (item) => {
        const balance = await getCoinBalance(item.coin_id, walletAddress);
        return { ...item, coinBalance: balance.data.balance.balance }; // Add 'balance' property to the object
      };
      // Use Promise.all to map each item to its balance
      const dataWithBalances = await Promise.all(data.data.map(getBalanceForItem));
      setUserCoinData(dataWithBalances)
      setMyCoinLoader(false)

    }
    catch (e) {
      setMyCoinLoader(false)
      let msg = errorMessage(e)
      toastMessageFailure(msg)
    }
  };


  const getAllCoinDetail = async (e) => {

    try {
      let { data } = await getAllCoin();
      const getBalanceForItem = async (item) => {
        const balance = await getCoinBalance(item.coin_id, walletAddress);
        return { ...item, coinBalance: balance.data.balance.balance }; // Add 'balance' property to the object
      };
      // Use Promise.all to map each item to its balance
      const dataWithBalances = await Promise.all(data.data.map(getBalanceForItem));
      setAllCoinData(dataWithBalances)
      setMyCoinLoader(false)

    }
    catch (e) {
      setMyCoinLoader(false)
      let msg = errorMessage(e)
      toastMessageFailure(msg)
    }
  };


  const openSccussModal = (sccussMessage) => {
    setError(sccussMessage);
    setIsModalOpen(true);
  };
  const closeSccussModal = () => {
    setError(null);
    setIsModalOpen(false);
  };
  const openErrorModal = (errorMessage) => {
    setError(errorMessage);
    setIsModalOpen(true);
  };
  const closeErrorModal = () => {
    setError(null);
    setIsModalOpen(false);
  };

  const handleRefresh = () => {
    setMyCoinLoader(true)
    getAllCoinDetail()
    getUserCoinDetail()

  };
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(walletAddress)
      .then(() => {
        setIsCopied(true)
        toastMessageInfo("You successfully copy the address")
      })
      .catch((error) => console.error("Copy failed:", error));
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName == "myCoins") {
      //  fetchUserData();
      // fetchBalances();
    }
  };
  const handleSend = async (address, amount, mnemonic, coinId, coinOwner) => {
    const decoded = jwt();
    const username = decoded.username;

    const coinbalance = await coinBalance(coinId, coinOwner);
    if (coinbalance >= amount) {
      const reqBody = {
        userName: username,
        coinId: coinId,
        senderAddress: mnemonic,
        receiverAddress: address,
        amount: amount,
      };
      const encryptedReqBody = encrypt(reqBody)
      try {
        const transferRes = await coinTransfer(encryptedReqBody);
        console.log(transferRes.message);
        openSccussModal(transferRes.message);
      } catch (error) {
        console.error("transfer error : ", error);
        openErrorModal(error.message);
      }
    } else {
      const errorMessage = "You don't have enough balance";
      openErrorModal(errorMessage);
    }

    console.log(
      `Sending  ${address} ${amount} ${mnemonic} ${coinId} ${coinOwner}`
    );
    // console.log(mnemonic);
    // console.log(coinId);
  };

  const handleMint = async (amount, coinId, mnemonic) => {
    if (!userWalletData || !userCoinData || userCoinData.length === 0) {
      console.warn("User wallet data or user coin data is missing or empty.");
      return;
    }

    if (userWalletData.account_id === userCoinData[0].coin_owner) {
      const requestBody = {
        sender: mnemonic,
        amount: amount,
        coinID: coinId,
      };
      console.log(requestBody);
      const encryptedRequestBody = encrypt(requestBody)

      try {
        const mintRes = await coinMint(encryptedRequestBody);
        successModal(mintRes.message);
        console.log(mintRes.message);
      } catch (error) {
        console.error("Error minting coins:", error.message);
        openErrorModal(error.message);
      }
    } else {
      const errorMessage = "You are not authorized to Mint!";
      openErrorModal(errorMessage);
    }
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const toggleModal1 = () => {
    setModalIsOpen1(!modalIsOpen1);
  };

  const handlePageChange = (newPage) => {
    const updatedPage = Math.max(1, newPage);
    setCurrentPage(updatedPage);
  };
  async function fetchData(currentPage) {
    try {
      const response = await getAllCoinData(currentPage);
      if (response && response.data) {
        setAllCoinData(response.data);
        fetchBalances();
      } else {
        console.error("Invalid response from getAllCoinData:", response);
      }
    } catch (error) {
      console.error("Error fetching coin data:", error);
    }
  }

  async function fetchWalletData() {
    try {
      const response = await UserWalletData();
      // console.log(response);
      setUserWalletData(response);
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  }
  const fetchBalances = async () => {
    const balanceData = {};

    for (const item of allCoinData) {
      console.log("item", item.coin_id, userWalletData.account_id)
      const balance = await coinBalance(
        item.coin_id,
        userWalletData.account_id
      );
      balanceData[item.coin_id] = balance;
    }
    // console.log(balanceData);
    // setBalances(balanceData);

    if (Object.keys(balanceData).length === 0) {
      setBalances(null);
    } else {
      setBalances(balanceData);
    }
  };

  // useEffect(() => {
  //   fetchWalletData();
  //   fetchData(currentPage);
  // }, [currentPage]);

  // useEffect(() => {
  //   fetchBalances();
  // }, [allCoinData,userWalletData]);

  return (
    <div className="inner-main">
      <div className="row">



        <ErrorAndLogoutModal
          visible={visible}

          onClose={() => {
            setVisible(false)
            localStorage.clear();
            window.location.href = "/";
          }}
          message={"Before using this website, it's important to note that you do not currently possess a Nucoin wallet. To proceed, kindly visit Nucoin's registration page to create your wallet, and then log in to this website."}
        ></ErrorAndLogoutModal>








        <div className="col-lg-6">
          <div className="boxes">
            <div className="d-flex align-items-center  justify-content-between">
              <div>
                <img
                  className="box-logo"
                  src="/assets/images/logo.png"
                  alt=""
                />
              </div>
            </div>
            <div className="wallet-adress">

              {myCoinLoader === true ? <div className="mt-2"> <BeatLoader color="#4788ff" /></div> : <h5>{walletAddress || "You don't have a Wallet!"}</h5>}


              <button className="copy-button" onClick={handleCopyClick}>
                {isCopied ? "Copied" : "Copy"}
              </button>
            </div>
            <div style={{ paddingTop: "45px" }}>
              <h3>Balance </h3>
              {myCoinLoader === true ? <div className="mt-2"> <BeatLoader color="#4788ff" /></div> : <h1>{walletBalance} NUC</h1>}

            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="boxes">
            <img
              className="box-bg"
              width={100}
              src="/assets/images/boxes-bg.png"
              alt="boxes-bg"
            />
            <h6>Tokenize Your World with NuCoin:</h6>
            <p>
              Seize the NuCoin opportunity, tokenize your world, and evolve your
              wealth. With NuCoin, be the first to embrace the evolution of
              wealth creation through tokenization.
            </p>
            <a href="">
              Read more{" "}
              <span>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.24609 2.25977L8.05343 5.07213L5.24609 7.8845"
                    stroke="white"
                    stroke-width="1.01491"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.66531 5.07227H1.97266"
                    stroke="white"
                    stroke-width="1.01491"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-box">
            {/* <Loader /> */}
            <div className="coin-tabs">
              <div className="ct-1">
                <div
                  className={`coin-list c-tab ${activeTab === "coinList" ? "active" : ""
                    }`}
                  onClick={() => handleTabClick("coinList")}
                >
                  <h2 className="pb-1 pt-3">Coin List</h2>
                </div>
                <div
                  className={`my-coins c-tab ${activeTab === "myCoins" ? "active" : ""
                    }`}
                  onClick={() => handleTabClick("myCoins")}
                >
                  <h2 className="pb-1 pt-3">My Coins</h2>
                </div>
              </div>
              <div
                onClick={handleRefresh}


                className="refresh">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  height="800px"
                  width="800px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 489.645 489.645"
                  xml="preserve"
                >
                  <g>
                    <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3   c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5   c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8   c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2   C414.856,432.511,548.256,314.811,460.656,132.911z" />
                  </g>
                </svg>
              </div>
            </div>
            {activeTab === "coinList" && (
              <div className="table-responsive">
                {myCoinLoader && <div className="mt-2"> <BarLoader size={30} width="100%" color="#4788ff" /></div>}
                <table>
                  <tbody>
                    <tr className="heading-row">
                      <th>Icon</th>
                      <th>Symbol</th>
                      <th>Name</th>
                      <th>Decimals</th>
                      <th>Total Supply</th>
                      <th>Balance</th>
                      <th>Distribution Address</th>
                      <th>Transfer</th>
                    </tr>
                    {Array.isArray(allCoinData) && allCoinData.length > 0 ? (
                      allCoinData.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <img style={{ width: 50, height: 50 }} src={`${constant.MULTICOIN_SERVICE_URL}/read-image?fileName=${item.coin_icon}&&username=${item.user_name}`} alt="coin-icon" />
                          </td>
                          <td>{item.coin_symbol}</td>
                          <td>{item.coin_name}</td>
                          <td>{item.coin_decimal}</td>
                          <td>
                            {item.total_supply}
                          </td>

                          <td>
                            {item.coinBalance}
                          </td>
                          <td>
                            <p className="truncated-text">{item.coin_owner}</p>
                          </td>
                          <td>
                            <a onClick={() => {
                              setSelectedCoin(item)
                              toggleModal()
                            }


                            } href="#transaction">
                              Make a Transaction
                            </a>
                          </td>
                          <TransectionModal
                            isOpen={modalIsOpen}
                            closeModal={toggleModal}
                            onSend={handleSend}
                            chooseItem={selectedCoin}
                            senderAddress={walletAddress}

                          />
                        </tr>
                        //))}
                      ))
                    ) : (

                      myCoinLoader === false && allCoinData.length == 0 &&

                      <tr>
                        <td colSpan="7">No Coin Available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* {allCoinData.length > 0 &&
                  <div className="pagination">
                    <div onClick={() => handlePageChange(currentPage - 1)}>
                      <i class="fa-solid fa-arrow-left fa-xl"></i>
                    </div>
                    <p>{currentPage}</p>
                    <div onClick={() => handlePageChange(currentPage + 1)}>
                      <i class="fa-solid fa-arrow-right fa-xl"></i>
                    </div>
                  </div>
                } */}
              </div>
            )}
            {activeTab === "myCoins" && (
              <div className="table-responsive">
                {myCoinLoader && <div className="mt-2"> <BarLoader size={30} width="100%" color="#4788ff" /></div>}
                <table>
                  <tbody>
                    <tr className="heading-row">
                      <th>Icon</th>
                      <th>Symbol</th>
                      <th>Name</th>
                      <th>Decimals</th>
                      <th>Total Supply</th>
                      <th>Balance</th>
                      <th>Distribution Address</th>
                      <th>Transfer</th>
                      <th>Minting </th>
                    </tr>
                    {Array.isArray(userCoinData) && userCoinData.length > 0 ? (
                      userCoinData.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <img style={{ width: 50, height: 50 }} src={`${constant.MULTICOIN_SERVICE_URL}/read-image?fileName=${item.coin_icon}&&username=${item.user_name}`} alt="coin-icon" />
                          </td>
                          <td>{item.coin_symbol}</td>
                          <td>{item.coin_name}</td>
                          <td>{item.coin_decimal}</td>
                          <td>
                            {item.total_supply}
                          </td>
                          <td>

                            {item.coinBalance}
                          </td>{" "}
                          <td>
                            <p className="truncated-text">{item.coin_owner}</p>
                          </td>
                          <td>
                            <a onClick={() => {
                              setSelectedCoin(item)
                              toggleModal()
                            }} href="#transaction">
                              Make a Transaction
                            </a>
                          </td>
                          <TransectionModal
                            isOpen={modalIsOpen}
                            closeModal={toggleModal}
                            onSend={handleSend}
                            chooseItem={selectedCoin}
                            senderAddress={walletAddress}
                          />
                          <td>
                            <a onClick={toggleModal1} href="#mint">
                              Mint
                            </a>
                          </td>
                          <MintModal
                            coinId={item.coin_id}
                            isOpen={modalIsOpen1}
                            closeModal={toggleModal1}
                            onSend={handleMint}
                          />
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No Coin Available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <ErrorModal
        isOpen={isModalOpen}
        closeModal={closeErrorModal}
        error={error}
      />
    </div>
  );
};

export default Main;
