import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Mobilemenu from "../Mobilemenu";
import Topbar from "../Topbar";
import '../dashboard.css'


import { useRouteMatch, Redirect } from "react-router-dom";
import Faq from "./FaqMain";


const FaqMain = () => {

  const router = useRouteMatch();


  return (
    <div id="dashboard-div">
      <Sidebar/>
      {/* {isMobileMenuVisible && <Mobilemenu />} */}
      <div className="main">
      <Topbar/>  
      <Faq />
       
      {/* <Topbar/>  
      <Main />  */}
      {/* {
        router.params.id === 'dashboard' && <Main /> 
      }
      {
        router.params.id  === 'creation' && <Creation />
      }
      {
        router.params.id  === 'process' && <Process />
      }
       {
        router.params.id  === 'faq' && <Faq />
      }
       {
        router.params.id  === 'notification' && <Notification />
      } */}
      </div>
    </div>
  );
};

export default FaqMain;
