import React from "react";
import Modal from "react-modal";
import "./ErrorModal.css"; // Import a CSS file for custom styling


// Define custom styles for the modal
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    position: "absolute",
    top: "50%",
    
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:"60%",
    padding: "0px",
    borderRadius: "15px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  },
};

const ErrorModal = ({ isOpen, closeModal, error }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Error Modal"
      className="error-modal"
    >
      <div className="error-modal">
        <h2 className="error-title">ERROR</h2>
        <p className="error-message pt-2 pb-2">{error}</p>
        <button className="close-button" onClick={closeModal}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
