import jwtDecode from "jwt-decode"

export default function jwt() {
    const yourToken = localStorage.getItem('authToken');
  
    if (yourToken) {
      try {
        const decoded = jwtDecode(yourToken);
        return decoded;
      } catch (error) {
        console.error("Error decoding token:", error);
        return null; 
      }
    } else {
      console.error("Token not found in localStorage");
      return null;
    }
  }