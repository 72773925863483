import axios from "axios";
import { encrypt } from "../../utils/HelperMethod";
import invoke from "../../utils/invoke";
import { constant } from "../../config/config";

export const addBusiness = (data) => {
    // data = encrypt(data);
    return invoke({
        method: "POST",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: "add-business-detail",
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const businessVerifcation = () => {
    // data = encrypt(data);
    return invoke({
        method: "GET",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: "bussiness-verify",
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const addCoin = (data) => {
    // data = encrypt(data);
    return invoke({
        method: "POST",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: "create-coin",
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const getUserCoin = () => {
    // data = encrypt(data);
    return invoke({
        method: "GET",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: "get-coin-of-user",
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};


export const getUserWalletAndBalance = () => {
    // data = encrypt(data);
    return invoke({
        method: "GET",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: "get-user-address-and-balance",
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const getCoinBalance = (coinID, address) => {

    return invoke({
        method: "POST",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: `get-coin-balance?coinId=${coinID}&&address=${address}`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};



export const getAllCoin = () => {
    // data = encrypt(data);
    return invoke({
        method: "GET",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: "get-all-coin",
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};


export const transferCoin = (data) => {
    data = encrypt(data);
    return invoke({
        method: "POST",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: "transfer",
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const mintCoin = (data) => {
    data = encrypt(data);
    return invoke({
        method: "POST",
        baseURL: constant.MULTICOIN_SERVICE_URL,
        route: "mint",
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

