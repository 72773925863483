import React from "react";
import AccordFaq from "./AccordFaq";

const Faq = () => {
  return (
    <div className="inner-main">

    
    <div className="accord-box">
      <AccordFaq />
    </div>
    </div>
  );
};

export default Faq;
