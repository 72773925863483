import axios from "axios";

const BASE_URL = process.env.REACT_APP_MULTICOIN_SERVICE_URL;

const coinTransfer = async (reqBody) => {
  const apiUrl = `${BASE_URL}/api/v1/multicoin/coin/transfer`;
  const authToken = localStorage.getItem('authToken');
  const headers = {
    Authorization: authToken,
  };

  try {
    console.log(reqBody);
    const response = await axios.post(apiUrl, reqBody, { headers });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error.response.data.errors[0];
  }
};

export default coinTransfer;
