import axios from "axios";

const coinBalance = async (coinI, coinOwner) => {
  const requestData = {
    coinId: coinI,
    address: coinOwner
  };
  const BASE_URL = process.env.REACT_APP_MULTICOIN_SERVICE_URL;

  const params = new URLSearchParams({
    coinId: coinI,
    address: coinOwner,
  }).toString();  
  // console.log(coinI);
  const apiUrl = `${BASE_URL}/api/v1/multicoin/coin/get-coin-balance?` + params;
  const authToken = localStorage.getItem('authToken');

  const config = {
    headers: {
      'Authorization': authToken
    }
  };

  try {
    const response = await axios.post(apiUrl,{}, config);
    
    if (response.status === 200) {
      // console.log(response.data.balance.balance); 
      return response.data.balance.balance; 
    } else {
      console.error("Error: Non-200 response balance:", response.status);
      throw new Error(`Non-200 response balance: ${response.status}`);
    }
  } catch (error) {
    console.error("Error: balance", error.message);
    throw error;
  }
};

export default coinBalance;
