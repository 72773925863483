import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Dashboard from "./component/dashboard/Dashboard";
import { useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { useState } from "react";
import { setCurrentUser } from "./redux/actions/auth";
import store from "./store";
import jwt_decode from "jwt-decode";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import CoinCreation from "./component/dashboard/CoinCreation/CoinCreation";
import CreateCoin from "./component/dashboard/CreateCoin/CreateCoin";
// import FaqMain from "./component/dashboard/Faq/Faq";
import FaqMain from "./component/dashboard/Faq/Faq";
import {NotificationSDK} from "notificationsdk";

import 'bootstrap/dist/css/bootstrap.css';

import LoginPage from "./component/Auth/login";

if (localStorage.jwtToken) {
  // Set auth token header auth
  // Decode token and get user info
  const decoded = jwt_decode(localStorage.jwtToken);
  console.log("decoded", decoded);
  store.dispatch(setCurrentUser(decoded));
}


function App() {

  return (
    <>
     <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" component={LoginPage} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/coin-creator" component={CoinCreation} />
          <PrivateRoute exact path="/create-coin" component={CreateCoin} />
          <PrivateRoute exact path="/faq" component={FaqMain} />
        </Switch>
        <NotificationSDK connectionURL='wss://notificationapi.nucoin.com.au/ws/project' apiBasePath='https://notificationapi.nucoin.com.au' projectKey="ff69efaaeb49bc9285dff72329a6d454" authToken={localStorage.getItem('jwtToken')} toastLogo={'/images/logo.png'}/>
      </BrowserRouter>
      </Provider>
    </>
  );
}




export default App;
