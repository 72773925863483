import axios from "axios";
// import { encrypt } from "./message";
import invoke from "../../utils/invoke";
import { encrypt } from "../../utils/HelperMethod";
import { constant } from "../../config/config";

export const signin = (data) => {
  data = encrypt(data);
  return invoke({
    method: "POST",
    baseURL: constant.AUTH_SERVICE_URL,
    route: "login",
    data: data,
  });
};



// const login = async (userData) =>{
  
//   const message = encrypt(userData)

//   const apiUrl = "login"; 

//   try {
//     const response = await axios.post(apiUrl, message);
//     const token = response.data.data.token;
//     localStorage.setItem('authToken', token);

//     const decoded = jwt()
    

//     // console.log();s
//     return decoded.username;
//   } catch (error) {
//     const errorMessage = error.response.data.errors;
//     // console.error("Error:", errorMessage);
//     throw errorMessage;
//   }

// }

// export default login;
