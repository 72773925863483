import Accordion from "react-bootstrap/Accordion";

function AccordFaq() {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q1</div>
            <div>What is the NuGenesis Network Launchpad?</div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          The NuGenesis Network Launchpad is a platform designed to facilitate
          the deployment of project coins on the NuGenesis blockchain. It
          provides an efficient and structured way for project developers to
          introduce their coin concepts to the network.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q2</div>
            <div>
              Do all projects get approved for launch on the NuGenesis Network?
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          No, all projects must undergo a vetting process before receiving
          approval. This vetting is crucial to maintain the integrity and
          quality of projects on the network. However, it's essential to note
          that the vetting is for our internal approval process only.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q3</div>
            <div>How long does the vetting process take?</div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          The duration of the vetting process can vary depending on the volume
          of applications and the intricacies of each project. We aim to review
          and provide feedback as swiftly as possible, but we also prioritize
          thoroughness to ensure the platform's standards are upheld.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q4</div>
            <div>
              What criteria does NuGenesis consider during the vetting process?
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          While we do not disclose all the specific criteria, the vetting
          process examines the project's viability, technical soundness, team
          credentials, and compliance with our platform's guidelines. It's
          imperative for projects to provide accurate and comprehensive details
          during the submission.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q5</div>
            <div>
              If a project is approved by NuGenesis, does that mean it's
              endorsed by the platform?
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          No. Approval only indicates that the project has passed our internal
          vetting process. NuGenesis is not responsible for any project that is
          launched on our Launchpad, and approval should not be considered an
          endorsement or validation of the project's merits or potential
          outcomes.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q6</div>
            <div>
              Are there any fees associated with launching a project on the
              NuGenesis Network?
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          The fee structure can vary based on several factors. We recommend
          checking our official documentation or reaching out to our support
          team for specific details about any associated costs.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q7</div>
            <div>
              Can we make updates to our project details after submission?
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          Changes after submission may complicate the vetting process. If
          there's an urgent need to update any details, contact our support team
          as soon as possible. Please note, significant changes might require a
          reevaluation of your project.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q8</div>
            <div>What happens if our project is not approved?</div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          If your project isn't approved, you'll receive feedback or reasons for
          the decision. You may have the opportunity to address the concerns
          raised and resubmit, but this will be determined on a case-by-case
          basis.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "15px" }}>Q9</div>
            <div>Who can we contact for more information or clarification?</div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: "10px 60px 30px" }}>
          For any further queries or clarification, please reach out to our
          dedicated support team through the contact information provided on our
          platform.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordFaq;
