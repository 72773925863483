import React, { useState } from "react";
import Modal from "react-modal";
import { errorMessage } from "../utils/HelperMethod";
import { FormErrorMessage, toastMessageSuccess } from "../utils/HelperMethod";
import { mintCoin } from "../api/coin/coin";

// Define the modal style
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    zIndex: '99999999',
    borderRadius: "20px",
    border: '1px solid black',
  },
};

Modal.setAppElement("#root"); // Set the root element

function MintModal({ isOpen, closeModal, onSend, coinId, }) {




  console.log("coinId", coinId)


  const [amount, setAmount] = useState("");
  const [mnemonic, setMnemonic] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);



  let resetState = () => {
    setIsLoading(false)
    setDisable(false)
    setMessage("")
    setError(false)
    setMnemonic("")
    setAmount(0)

  }


  const handleSend = async () => {

    if (parseFloat(amount) <= 0) {
      setError(true);
      setMessage("The amount must be above zero.");
      return;
    }
    else if (mnemonic === "" || mnemonic === null) {
      setError(true);
      setMessage("Mnemonic is required!");
      return;
    }
    try {
      let mintObject = {
       
        sender: mnemonic,
       amount: amount,
       coinID:coinId
      }
      setError(false);
      setMessage("");
      setIsLoading(true)
      setDisable(true)

      await mintCoin(mintObject)
      toastMessageSuccess("The coin minting process has been successfully completed by you.")
      resetState()
      closeModal()



    }
    catch (e) {
      let msg = errorMessage(e)
      setError(true);
      setMessage(msg);
      setIsLoading(false)
      setDisable(false)


    }




  };

  return (
    <Modal
      isOpen={isOpen}
      //onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Send Modal"
    >
      <div className="modal-content">
        <h2 className="modal-title">Mint Funds</h2>
        <div className="modal-input">
          <label htmlFor="amount">Please input the amount:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="modal-input">
          <label htmlFor="amount">Please provide 12-word mnemonics:</label>
          <input
            type="text"
            id="mnemonic"
            value={mnemonic} s
            onChange={(e) => setMnemonic(e.target.value)}
          />
        </div>
        {error &&
          <div style={{ marginTop: 20 }}>
            <FormErrorMessage message={message} />
          </div>
        }
        <div className="modal-buttons">
          <button className="modal-btn" disabled={disable} onClick={handleSend}>
            {isLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              "Send"
            )}

          </button>
          <button disabled={disable} className="modal-btn" onClick={() => {
            resetState()
            closeModal()
          }}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default MintModal;
