import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Noti from "./Noti";

const Sidebar = ({ isOpen, toggleSidebar }) => {

  const handleSignOut = (e) => {
    e.preventDefault();

    localStorage.clear();
    window.location.href = "/";
  };

  const router = useRouteMatch();

  return (
    <div>
      <Link
        id="navigate-dashboard"
        to="/dashboard"
        style={{ display: "none" }}
      />
      <Link
        id="navigate-creation"
        to="/coin-creator"
        style={{ display: "none" }}
      />
      <Link
        id="navigate-process"
        to="/create-coin"
        style={{ display: "none" }}
      />
      <Link id="navigate-faq" to="/faq" style={{ display: "none" }} />
      <Link
        id="navigate-notification"
        to="/dashboard/notification"
        style={{ display: "none" }}
      />
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className={`navigation ${isOpen ? "open" : ""}`}>
          <a className="brand">
            <img src="/assets/images/logo.png" alt="dashlogo" />
          </a>

          <div
            className={`links ${router.params.id === "dashboard" ? "isActive" : ""
              }`}
            onClick={() =>
              document.getElementById("navigate-dashboard").click()
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/dashboard-icon.png" alt="dashboard-icon" />

            <p>Dashboard</p>
          </div>
          <div
            className={`links ${router.params.id === "creation" ? "isActive" : ""
              }`}
            onClick={() => document.getElementById("navigate-creation").click()}
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/ceration.png" alt="creation" />

            <p>Coin Creation Application</p>
          </div>
          <div
            className={`links ${router.params.id === "process" ? "isActive" : ""
              }`}
            onClick={() => document.getElementById("navigate-process").click()}
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/process.png" alt="process" />

            <p>Coin Creation Process</p>
          </div>
          <div
            className={`links ${router.params.id === "faq" ? "isActive" : ""}`}
            onClick={() => document.getElementById("navigate-faq").click()}
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/faq.png" alt="faq" />

            <p>FAQ’s</p>
          </div>
          {/* <Noti></Noti> */}

          {/* Rest of the sidebar content */}
          {/* {activeLink === "purchaseCoin" && <PurchaseToken />}
        {activeLink === "transaction" && <Transactiondetails />} */}
          <div className="bottom-adjustment">
            <div className="main-docu">
              <div className="documentation">
                <div
                  style={{
                    backgroundColor: "white",
                    width: "fit-content",
                    padding: "5px",
                    borderRadius: "10px",
                  }}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 3.5C7.03125 3.5 3 7.53125 3 12.5C3 17.4687 7.03125 21.5 12 21.5C16.9687 21.5 21 17.4687 21 12.5C21 7.53125 16.9687 3.5 12 3.5ZM11.7187 17.75C11.5333 17.75 11.3521 17.695 11.1979 17.592C11.0437 17.489 10.9236 17.3426 10.8526 17.1713C10.7817 17 10.7631 16.8115 10.7993 16.6296C10.8354 16.4477 10.9247 16.2807 11.0558 16.1496C11.1869 16.0185 11.354 15.9292 11.5359 15.893C11.7177 15.8568 11.9062 15.8754 12.0775 15.9464C12.2488 16.0173 12.3952 16.1375 12.4983 16.2916C12.6013 16.4458 12.6562 16.6271 12.6562 16.8125C12.6562 17.0611 12.5575 17.2996 12.3817 17.4754C12.2058 17.6512 11.9674 17.75 11.7187 17.75ZM13.2862 12.9687C12.5264 13.4787 12.4219 13.9461 12.4219 14.375C12.4219 14.549 12.3527 14.716 12.2297 14.839C12.1066 14.9621 11.9397 15.0312 11.7656 15.0312C11.5916 15.0312 11.4247 14.9621 11.3016 14.839C11.1785 14.716 11.1094 14.549 11.1094 14.375C11.1094 13.348 11.5819 12.5314 12.5541 11.8784C13.4578 11.2719 13.9687 10.8875 13.9687 10.0423C13.9687 9.46766 13.6406 9.03125 12.9614 8.70828C12.8016 8.63234 12.4458 8.55828 12.008 8.56344C11.4586 8.57047 11.032 8.70172 10.7034 8.96609C10.0837 9.46484 10.0312 10.0077 10.0312 10.0156C10.0271 10.1018 10.006 10.1863 9.96919 10.2644C9.93237 10.3424 9.88054 10.4124 9.81666 10.4704C9.75279 10.5284 9.67811 10.5732 9.5969 10.6024C9.51569 10.6315 9.42954 10.6444 9.34336 10.6402C9.25718 10.6361 9.17266 10.615 9.09463 10.5782C9.0166 10.5414 8.94659 10.4895 8.88859 10.4256C8.83059 10.3618 8.78574 10.2871 8.75659 10.2059C8.72745 10.1247 8.71459 10.0385 8.71875 9.95234C8.72391 9.83844 8.80312 8.81234 9.87984 7.94609C10.4381 7.49703 11.1483 7.26359 11.9892 7.25328C12.5845 7.24625 13.1437 7.34703 13.523 7.52609C14.6578 8.06281 15.2812 8.95766 15.2812 10.0423C15.2812 11.6281 14.2214 12.3402 13.2862 12.9687Z"
                      fill="url(#paint0_linear_3507_15898)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3507_15898"
                        x1="12"
                        y1="3.5"
                        x2="12"
                        y2="21.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8146FF" />
                        <stop offset="1" stop-color="#5BC4FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h5>Need help?</h5>
                <p>Please check our docs</p>
                <button className="">
                  <a href="">DOCUMENTATION</a>
                </button>
              </div>
              <button className="signout">
                <a href="">
                  <div className="d-flex align-items-center ">
                    <svg
                      width="31"
                      style={{ marginRight: "60px" }}
                      height="24"
                      viewBox="0 0 31 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5595 7C16.5595 6.44772 16.1118 6 15.5595 6C15.0072 6 14.5595 6.44772 14.5595 7V11H9.40234C8.85006 11 8.40234 11.4477 8.40234 12C8.40234 12.5523 8.85006 13 9.40234 13H14.5595V17C14.5595 17.5523 15.0072 18 15.5595 18C16.1118 18 16.5595 17.5523 16.5595 17V13H21.7187C22.271 13 22.7187 12.5523 22.7187 12C22.7187 11.4477 22.271 11 21.7187 11H16.5595V7Z"
                        fill="white"
                      />
                    </svg>

                    <div onClick={handleSignOut}>Sign Out</div>
                  </div>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
