import React, { useState } from "react";
// import { Redirect } from 'react-router-dom';
import login from "../../api/auth/auth";
import ErrorModal from "../../modals/ErrorModal";
import Loader from "../loader";
import { constant } from "../../config/config";
import { signin } from "../../api/auth/auth";
import { FormErrorMessage } from "../../utils/HelperMethod";
import { errorMessage } from "../../utils/HelperMethod";

function LoginPage({ handleLogin2 }) {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");



  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);


  const handleLogin = async (e) => {

    e.preventDefault();


    if (username === "" || username === null) {
      setError(true);
      setMessage("Username is required!");
      return;
    } else if (password === "" || password === null) {
      setError(true);
      setMessage("Password is required!");
      return;
    }







    const userData = {
      userName: username,
      password: password,
    };

    try {
      setError(false)
      setMessage("")
      setIsLoading(true)
      setDisable(true)
      const { data } = await signin(userData);
      setDisable(false)
      setIsLoading(false)
      localStorage.setItem('jwtToken', data.data.token)
      window.location.href = "/dashboard";



    }
    catch (e) {

      let msg = errorMessage(e)
      setDisable(false)
      setError(true)
      setIsLoading(false)
      setMessage(msg)


    }
  }

  return (

    <div className="container-fluid main-login">
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <div className="login">
            <img src="/assets/images/logo.png" alt="dashlogo" />
            <form className="login-form pt-5">
              <h1>Login</h1>
              <div className="form-group login-form">
                <label htmlFor="username">Username:</label>
                <input
                  required
                  type="text"
                  placeholder="Enter your UserName"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  required
                  type="password"
                  id="password"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error &&
                <div style={{ marginTop: 20 }}>
                  <FormErrorMessage message={message} />
                </div>
              }

              <button
                onClick={handleLogin}
                className="signout text-white"
                disabled={disable}
                type="submit"
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "Login"
                )}
              </button>
              <h3 className="pt-3">
                Don’t have an account? <a target="_blank" href={constant.NUCOIN_URL}>Sign up here</a>
              </h3>
            </form>
          </div>
        </div>
        <div className="col-8 relative-part-img">
          <div className="text-abs">
            <h2 className="pb-3">Don't have an account?</h2>
            <p>
              If you don't have a registered account click{" "}
              <a target="_blank" href={constant.NUCOIN_URL}>Sign up</a> and <br></br>follow instructions in the
              form displayed.
            </p>
          </div>
          <img src="/assets/images/LoginBG.png" alt="LoginBG" />
        </div>
      </div>

      {/* <ErrorModal
        isOpen={isModalOpen}
        closeModal={closeErrorModal}
        error={error}
      /> */}
    </div>

  );
}

export default LoginPage;
