// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import ErrorOutline from '@material-ui/icons/ErrorOutline';

export default class ErrorAndLogoutModal extends React.Component {

    render() {
        return (
            <Modal dialogClassName="ErrorAlert text-center"
                show={this.props.visible}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <ErrorOutline />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{this.props.title}</h4>
                    <p>{this.props.message}</p>
                    <button type="button" class="btn btn-secondary Cancel mt-2" onClick={() => this.props.onClose(this.props.sessionExpired)}>Close</button>
                </Modal.Body>
            </Modal>

        )
    }


}