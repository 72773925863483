import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";

const Topbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [isNavOpen, setIsNavOpen] = useState(false);
  const [userImg, setUserImg] = useState(null);



  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    // setIsNavOpen(false);
  };



  return (
    <>
      {isOpen && (
        <div className="sidebar open">
          {/* Include the Sidebar component here */}
          <Sidebar />
        </div>
      )}

      <div className="sticky-top">
        <div className="topbar">
          <div className="d-flex align-items-center ">
            <div className="menu-icon" onClick={toggleSidebar}>
              <img
                style={{ marginRight: "10px" }}
                src="/assets/images/menu.png"
                alt="menu"
              />
            </div>
            <h1>Coin Creator</h1>
          </div>
          <div className="d-flex" >
            {/* <svg
            style={{marginRight:"20px"}}
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="15" fill="#F7F7FF" />
              <path
                d="M15.4051 24.05C16.428 24.05 17.2564 23.2215 17.2564 22.1987H13.5538C13.5538 23.2215 14.3823 24.05 15.4051 24.05ZM20.959 18.4962V13.8679C20.959 11.0216 19.4456 8.64733 16.7936 8.0179V7.38846C16.7936 6.62018 16.1734 6 15.4051 6C14.6368 6 14.0167 6.62018 14.0167 7.38846V8.0179C11.3647 8.64733 9.85128 11.0216 9.85128 13.8679V18.4962L8 20.3474V21.2731H22.8103V20.3474L20.959 18.4962Z"
                fill="#9B9FAB"
              />
              <circle cx="20" cy="10" r="3" fill="white" />
              <circle cx="20" cy="10" r="2" fill="#FF3D3D" />
            </svg> */}

            {userImg ? ( // Check if userimg is set
              <img
                style={{ borderRadius: "30px", marginRight: "20px" }}
                src={userImg}
                alt="User Image"
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                height="35px"
                width="35px"
                version="1.1"
                id="Layer_1"
                viewBox="0 0 402.161 402.161"
              >
                <g>
                  <g>
                    <g>
                      <path d="M201.08,49.778c-38.794,0-70.355,31.561-70.355,70.355c0,18.828,7.425,40.193,19.862,57.151     c14.067,19.181,32,29.745,50.493,29.745c18.494,0,36.426-10.563,50.494-29.745c12.437-16.958,19.862-38.323,19.862-57.151     C271.436,81.339,239.874,49.778,201.08,49.778z M201.08,192.029c-13.396,0-27.391-8.607-38.397-23.616     c-10.46-14.262-16.958-32.762-16.958-48.28c0-30.523,24.832-55.355,55.355-55.355s55.355,24.832,55.355,55.355     C256.436,151.824,230.372,192.029,201.08,192.029z" />
                      <path d="M201.08,0C109.387,0,34.788,74.598,34.788,166.292c0,91.693,74.598,166.292,166.292,166.292     s166.292-74.598,166.292-166.292C367.372,74.598,292.773,0,201.08,0z M201.08,317.584c-30.099-0.001-58.171-8.839-81.763-24.052     c0.82-22.969,11.218-44.503,28.824-59.454c6.996-5.941,17.212-6.59,25.422-1.615c8.868,5.374,18.127,8.099,27.52,8.099     c9.391,0,18.647-2.724,27.511-8.095c8.201-4.97,18.39-4.345,25.353,1.555c17.619,14.93,28.076,36.526,28.895,59.512     C259.25,308.746,231.178,317.584,201.08,317.584z M296.981,283.218c-3.239-23.483-15.011-45.111-33.337-60.64     c-11.89-10.074-29.1-11.256-42.824-2.939c-12.974,7.861-26.506,7.86-39.483-0.004c-13.74-8.327-30.981-7.116-42.906,3.01     c-18.31,15.549-30.035,37.115-33.265,60.563c-33.789-27.77-55.378-69.868-55.378-116.915C49.788,82.869,117.658,15,201.08,15     c83.423,0,151.292,67.869,151.292,151.292C352.372,213.345,330.778,255.448,296.981,283.218z" />
                      <path d="M302.806,352.372H99.354c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h203.452c4.142,0,7.5-3.358,7.5-7.5     C310.307,355.73,306.948,352.372,302.806,352.372z" />
                      <path d="M302.806,387.161H99.354c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h203.452c4.142,0,7.5-3.358,7.5-7.5     C310.307,390.519,306.948,387.161,302.806,387.161z" />
                    </g>
                  </g>
                </g>
              </svg>
            )}
            <h3 style={{ marginRight: "20px" }}>{props.auth.user.username}</h3>
            {/* <svg
              width="14"
              style={{ marginleft: "20px" }}
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.42139 7.52509L13.0714 1.86509C13.1651 1.77213 13.2395 1.66153 13.2903 1.53967C13.341 1.41781 13.3672 1.2871 13.3672 1.15509C13.3672 1.02308 13.341 0.892372 13.2903 0.770513C13.2395 0.648653 13.1651 0.538052 13.0714 0.445089C12.884 0.258838 12.6306 0.154297 12.3664 0.154297C12.1022 0.154297 11.8488 0.258838 11.6614 0.445089L6.66139 5.39509L1.71139 0.445089C1.52403 0.258838 1.27058 0.154296 1.00639 0.154296C0.742204 0.154296 0.488752 0.258838 0.30139 0.445089C0.206902 0.537703 0.131731 0.648148 0.0802345 0.770024C0.028738 0.891899 0.00193974 1.02278 0.00139041 1.15509C0.00193972 1.2874 0.028738 1.41828 0.0802345 1.54015C0.131731 1.66203 0.206901 1.77247 0.30139 1.86509L5.95139 7.52509C6.04503 7.62659 6.15868 7.7076 6.28518 7.76301C6.41168 7.81842 6.54829 7.84702 6.68639 7.84702C6.82449 7.84702 6.9611 7.81842 7.0876 7.76301C7.21409 7.7076 7.32775 7.62659 7.42139 7.52509Z"
                fill="#94979E"
              />
            </svg> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.authReducer,

});
// export default Topbar;
export default connect(mapStateToProps, {})(Topbar)
