import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Define a CSS class name for the button based on the 'show' state
  const buttonClassName = show ? "isActive" : "inactive-button";

  return (
    <>
      <Button
        style={{
          cursor: "pointer",
          background: "transparent",
          width: "100%",
          border: "1px solid white",
        }}
        onClick={handleShow}
        className={`me-2 links canvas ${buttonClassName}`}
      >
        <img src="/assets/images/notification.png" alt="notification" />{" "}
        Notifications
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notification</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex align-items-center noti-row">
            <div className="d-flex align-items-center">
              <img style={{marginRight:"10px"}} src="/assets/images/avatar.png" alt="avatar" />
              <div>
                <h5>Agent Rose</h5>
                <p>Message:  Please, kindly do take a snap-shot of the card.</p>
              </div>
            </div>
            <div className="time">
              <h6> 18min</h6>
             
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Noti() {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default Noti;
