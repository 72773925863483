import React, { useState } from "react";
import Modal from "react-modal";

// Define the modal style
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    zIndex:'99999999',
    borderRadius: "20px",
    border:'1px solid black',
  },
};

Modal.setAppElement("#root"); // Set the root element

function MintModal({ isOpen, closeModal, onSend, coinId }) {
  const [amount, setAmount] = useState("");

  const handleSend1 = () => {
    setAmount("");
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Send Modal"
    >
      <div className="modal-content">
        
      </div>
    </Modal>
  );
}

export default MintModal;
