import React, { useState } from "react";
import Modal from "react-modal";
import { FormErrorMessage, toastMessageSuccess } from "../utils/HelperMethod";
import { toastMessageFailure } from "../utils/HelperMethod";
import { errorMessage } from "../utils/HelperMethod";
import { transferCoin } from "../api/coin/coin";

// Define the modal style
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "20px",
    border: '1px solid black',
  },
};

// Modal.setAppElement("#root");

function TransectionModal({ isOpen, closeModal, onSend, coinId, coinOwner, chooseItem, senderAddress }) {
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [mnemonic, setMnemonic] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  console.log("Props chooseItem", coinId, chooseItem)

  let resetState = () => {
    setIsLoading(false)
    setDisable(false)
    setMessage("")
    setError(false)
    setMnemonic("")
    setAmount(0)
    setAddress("")
  }

  const handleSend = async () => {

    if (address === "" || address === null) {
      setError(true);
      setMessage("Address is required!");
      return;
    }
    else if (parseFloat(amount) <= 0) {
      setError(true);
      setMessage("The amount must be above zero.");
      return;
    }
    else if (mnemonic === "" || mnemonic === null) {
      setError(true);
      setMessage("Mnemonic is required!");
      return;
    }
    try {
      let transferObject = {
        coinId: chooseItem.coin_id,
        senderAddress: mnemonic,
        receiverAddress: address,
        amount: amount,
      }
      setError(false);
      setMessage("");
      setIsLoading(true)
      setDisable(true)

      await transferCoin(transferObject)
      toastMessageSuccess("You have successfully completed the coin transfer.")
      resetState()
      closeModal()
     


    }
    catch (e) {
      let msg = errorMessage(e)
      setError(true);
      setMessage(msg);
      setIsLoading(false)
      setDisable(false)


    }




  };

  return (
    <Modal
      isOpen={isOpen}
      //onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Send Modal"
    >
      <div className="modal-content">
        <h2 className="modal-title">Send Funds</h2>
        <div className="modal-input">
          <label htmlFor="address">Please provide the recipient's address:</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="modal-input">
          <label htmlFor="amount">Please input the amount:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="modal-input">
          <label htmlFor="amount">Please provide 12-word mnemonics:</label>
          <input
            type="text"
            id="mnemonic"
            value={mnemonic} 
            onChange={(e) => setMnemonic(e.target.value)}
          />
        </div>
        {error &&
          <div style={{ marginTop: 20 }}>
            <FormErrorMessage message={message} />
          </div>
        }
        <div className="modal-buttons">
          <button disabled={disable} className="modal-btn" onClick={handleSend}>
            {isLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              "Send"
            )}
          </button>
          <button disabled={disable} className="modal-btn" onClick={() => {

            resetState()
            closeModal()
          }}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default TransectionModal;
