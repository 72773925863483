import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Mobilemenu from "./Mobilemenu";
import Topbar from "./Topbar";
import './dashboard.css'


import { useRouteMatch, Redirect } from "react-router-dom";

import Main from "./Main";
import Creation from "./CoinCreation/Creation";
import Process from "./CreateCoin/Process";
import Faq from "./Faq/Faq";
import Notification from "./Notification";

const Dashboard = () => {

  const router = useRouteMatch();


  return (
    <div id="dashboard-div">
      <Sidebar/>
      {/* {isMobileMenuVisible && <Mobilemenu />} */}
      <div className="main">
      <Topbar/>  
      <Main ></Main>
       
      {/* <Topbar/>  
      <Main />  */}
      {/* {
        router.params.id === 'dashboard' && <Main /> 
      }
      {
        router.params.id  === 'creation' && <Creation />
      }
      {
        router.params.id  === 'process' && <Process />
      }
       {
        router.params.id  === 'faq' && <Faq />
      }
       {
        router.params.id  === 'notification' && <Notification />
      } */}
      </div>
    </div>
  );
};

export default Dashboard;
